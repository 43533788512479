import React from 'react';
import {
    InputGroup, 
    FormControl, 
    Button,
   
} from 'react-bootstrap'
import Autocomplete from './Autocomplete'
import { API_URI } from '../uri';
import {postToApi, getFromApi} from '../callApi'

class AddItem extends React.Component {

    constructor(props) {
        super(props)
        this.itemForm = React.createRef()
        this.autocomplete = React.createRef()
       
    }

    defaultState = {
        newProduct: {
            unit: 'stuk',
            qty: 1
        },
        loading: false,
        valid: false,
        user: {}
    };

    state = this.defaultState;
    
    

        
    handleQtyChange = (e) => {
        const val = e.target.value;
        this.setState( {
            newProduct: { ...this.state.newProduct, qty: val },
            qty: e.target.value
        })
   
    }

    handleUnitChange = (e) => {
        this.setState({ 
            newProduct: {...this.state.newProduct, unit: e.target.value },
            unit: e.target.value
        })
    }

    handleProductChange = (e) => {
        this.setState({ 
            newProduct: {...this.state.newProduct, product: e.target.value }
        })
    }

    handleSuggestedChange = (item) => {
        
        this.setState({
            newProduct: {...this.state.newProduct, 
                product: item.product,
                product_id: item.id,
                category: item.category_name,
                category_id: item.category_id
            } ,
            
        })
    }
    
    handleValidation = (isValid) => {
        this.setState({valid: isValid})
    }

    handleClick = async (e) => {
        this.setState({
            loading: true
        })

        const prettyProduct = (this.state.newProduct.qty > 1 && this.state.newProduct.unit === 'stuk')  ? {...this.state.newProduct, unit: 'stuks' } : {...this.state.newProduct}
        
        const uri = `${API_URI}/shoppinglist/additem`;

        const {id, user_hash, group_hash} = await postToApi(uri, prettyProduct)
        
        prettyProduct.id = id
        prettyProduct.group_hash = group_hash
        prettyProduct.user_hash = user_hash
        this.props.addedProduct(prettyProduct)
        
        this.autocomplete.current.state.userInput = ""
        this.itemForm.current.reset()
        this.setState(this.defaultState)
        
    }

    getSuggestions = async () => {
        const uri =  `${API_URI}/products`

        const suggestions_ = await getFromApi(uri)
        suggestions_.reverse()
        this.setState({
            suggestions: suggestions_,
            //loading: false
        })
    }


    componentDidMount() {
        this.getSuggestions()
    }

    getButton = () => {
        if (this.state.loading) {
            return (
                <Button disabled onClick={() => {}} variant="outline-secondary"> <em>moment</em> </Button>
            )
        }
        else {
            return <Button disabled={!this.state.valid} onClick={this.handleClick} variant="outline-primary">Voeg toe</Button>
        }
    }

    render() {
        const {suggestions} = this.state
       
            return (
                <form onSubmit={_ => {}} ref={this.itemForm}>
                <InputGroup className="mb-3">
                    
                        <Autocomplete ref={this.autocomplete} placeholder="Product" validInput={this.handleValidation} currentProduct={this.handleSuggestedChange} suggestions={suggestions} />
                    
                        <FormControl placeholder="1" onChange={this.handleQtyChange}  />
                        
                        <FormControl 
                            onChange={this.handleUnitChange}
                            as="select"
                            variant="outline-secondary"
                            id="input-group-dropdown-1" 
                        >
                            <option>stuk</option>
                            <option>kg</option>
                            <option>gram</option>
                            <option>liter</option>
                        </FormControl>
        
                        
                        
                        <InputGroup.Append>
                        
                            {this.getButton()}
                        </InputGroup.Append>
                </InputGroup>
                </form>
            )
        
        }
}

export default AddItem