const getFromApi = async (uri) => {
   
    const response = await fetch(uri, {
        headers: {
            "Authorization": "Basic "+sessionStorage.getItem('token'),
            'Content-Type': 'application/json',
        },
    })

    if (!response.ok) {
        sessionStorage.removeItem("token")
        window.location.replace('/');
    }
   
    const json = await response.json()
    return json
}

const postToApi = async (uri, data, responseCallback = () => {}) => {
    
    try {
        const response = await fetch(uri, {
            method: 'post',
            headers: {
                "Authorization": "Basic "+sessionStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        responseCallback(response)
    
        if (!response.ok) {
            sessionStorage.removeItem("token")
            window.location.replace('/');
        }
       
        const json = await response.json()
        return json

    }
    catch(err) {
        console.log(err)
    }
}
export { getFromApi, postToApi }