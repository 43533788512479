import React, {useState, useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  HashRouter as Router, 
  Switch,
  Route,
} from 'react-router-dom'

import Login from "./components/Login"

//Components
import ShoppinglistPage from './components/Pages/ShoppinglistPage'
import AccountPage from './components/Pages/AccountPage.js'
import AdminPage from './components/Pages/AdminPage';
import Navigation from './components/Navigation'

import { postToApi } from './callApi';
import { API_URI } from './uri';

function App() {

  const [token, setToken] = useState(null)
  const [is_admin, setAdmin] = useState(false)

  const css = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px'
  };

  useEffect( () => {
    const the_token = sessionStorage.getItem('token')
    setToken(the_token)
  }, [])

  const checkCredentials = async () => {
    const uri = `${API_URI}/checkcredentials`
    const is_admin = await postToApi(uri)
    setAdmin(is_admin)
 }

 
  
  if (token) {
    checkCredentials()

    const navigation_links = is_admin ?  [
      {
        title: 'Administratie', 
        path: '/admin'
      },
      { 
        title: 'Account',
        path: '/account'
      }
    ]
    : 
    [
      {
        title: 'Account',
        path: '/account'
      }
    ]

    if (is_admin) {
      return (
        <Router>
          <Navigation links={navigation_links}/>
    
          <div style={css}>
            <Switch>
              <Route exact path="/">
                <ShoppinglistPage />
              </Route>

              <Route exact path="/admin">
                <AdminPage />
              </Route>

              <Route exact path="/account">
                <AccountPage />
              </Route>
              
            </Switch>
          </div>
    
        </Router>
      );
    }
    else {
      return (
        <Router>
        <Navigation links={navigation_links}/>
  
        <div style={css}>
          <Switch>
            <Route exact path="/">
              <ShoppinglistPage />
            </Route>

            <Route exact path="/account">
              <AccountPage />
            </Route>
          </Switch>
        </div>
  
      </Router>
      )
    }
  }
  else {
    return (
      <div style={css}>
        <Login />
      </div>
    )
  }

}

export default App;
