import React from 'react'
import PropTypes from 'prop-types'
import './Autocomplete.css'
import {FormControl} from 'react-bootstrap'

class Autocomplete extends React.Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    }

    static defaultProps = {
        suggestions: []
    }

    state = {
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: "",
        currentProduct: {},
        valid: false
    }

    getProductFromSuggestions = (id) => {
        
        const filtered = this.props.suggestions.filter(item => item.id === +id)
        return filtered[0]
    }

    handleClick = (e) => {
        const newProduct = this.getProductFromSuggestions(e.target.id);
        
        this.setState({
            activeSuggestion:0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
            currentProduct: newProduct,
            
        }, () => {
            const product = this.state.currentProduct
            this.props.validInput(true)
            this.props.currentProduct(product)
        })
    }

    handleChange = (e) => {
        const {suggestions} = this.props;
        const userInput = e.currentTarget.value.toLowerCase()

        // Filter our suggestions that don't contain the user's input
        const filtered = suggestions.filter(suggestion => suggestion.product.toLowerCase().indexOf( userInput.toLowerCase() ) > -1 )

        const presorted = filtered.sort((a, b) => {
            if (a.product < b.product) return -1
            if (a.product > b.product) return 1
            return 0
        })
        // Sort the filtered results based on the userInput's string;
        // whatever the current input string is, the suggestion with the earliest
        // substring of that input string will be presented first.
        const sorted = presorted.sort( (a, b) => {
            const a_product = a.product.toLowerCase();
            const b_product = b.product.toLowerCase();
            if (a_product.indexOf(userInput) < b_product.indexOf(userInput)) { return -1}
            if (a_product.indexOf(userInput) > b_product.indexOf(userInput)) { return 1}
            return 0
        }

        )
        // Update the user input and filtered suggestions, reset
        // the suggestion and make the suggestions are shown.
        this.setState({
            activeSuggestion:0,
            filteredSuggestions: sorted,
            showSuggestions: true,
            userInput: e.currentTarget.value
        })

        
    }

    handleKeyDown = (e) => {
        const {
            activeSuggestion, 
            filteredSuggestions
        } = this.state

        
        
        // Case user pressed Enter
        if(e.keyCode === 13) { //Enter
           
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion].product,
                currentProduct: filteredSuggestions[activeSuggestion],

            }, () => {
                const product = this.state.currentProduct
                this.props.validInput(true)
                this.props.currentProduct(product)
            })
        }
        else if (e.keyCode === 9) { // TAB 
            // do nothing particular
        }
        else if (e.keyCode === 38) { //Up-arrow
            if (activeSuggestion === 0) return;
            this.setState({
                activeSuggestion: activeSuggestion - 1
            })

        }
        else if (e.keyCode === 40) { //Down-arrow

            if (activeSuggestion - 1  === filteredSuggestions.length) return;
            this.setState({
                activeSuggestion: activeSuggestion + 1
            }) 
        }
        else {
            this.props.validInput(false)
        }
        
    }

    render() {
        const {showSuggestions, userInput, filteredSuggestions, activeSuggestion} = this.state
        let suggestionListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionListComponent = (<ul className="suggestions">
                    {filteredSuggestions.map( (suggestion, i) => {
                        let className;
                        if (i === activeSuggestion) className = "suggestion-active"

                        return (
                            <li
                                className={className}
                                key={suggestion.id}
                                onClick={this.handleClick}
                                id={suggestion.id}
                        >{suggestion.product}</li>
                        )
                    })}
                </ul>)
            } //if(filteredSuggestions.length)
            else {
                //No suggestions!
                suggestionListComponent = (
                    <div></div>
                )
            }
        }

        return (
            <>
                <FormControl
                    //type="text"
                    autoFocus 
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    value={userInput}
                    placeholder={this.props.placeholder}
                ></FormControl>
                {suggestionListComponent}
            </>
        )
    }
}

export default Autocomplete