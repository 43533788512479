import React from 'react';
import Shoppinglist from '../Shoppinglist'
import {ModalTitle} from 'react-bootstrap'

class ShoppinglistPage extends React.Component {
   
    render() {
	const json_str = sessionStorage.getItem('user') 
	const {name_first} = (json_str) ?  JSON.parse( json_str ) : {name_first: "Anoniem"}
        return (
            <>
            <ModalTitle className="mb-3">{name_first}'s boodschappenlijstje</ModalTitle>
            <Shoppinglist />
            </>
        )
    }
}

export default ShoppinglistPage
