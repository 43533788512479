import React from 'react'
import { Table, Button } from 'react-bootstrap'
import { postToApi } from '../callApi'
import { API_URI } from '../uri'
import AddUser from './Admin.AddUser'

export default function UsersOverview(props) {

    
    const css = {
        marginTop: '3rem'
    }

    const removeUser = async (user_hash) => {
        if (window.confirm("Weet je zeker dat je deze gebruiker wil verwijderen?")) {
           
            const uri = `${API_URI}/user/remove`
            await postToApi(uri, {user_hash})
           
            window.location.reload()
        }
    }

    const blockOrUnblockUser = async (user) => {
        const message = user.active === 1 ? "Weet je zeker dat je deze gebruiker wil bannen?" : "Weet je zeker dat je de ban wil opheffen?"
        const uri =  user.active === 1 ? `${API_URI}/user/block` : `${API_URI}/user/unblock`
        if (window.confirm(message)) {
            const {user_hash} = user
            await postToApi(uri, {user_hash})
           
            window.location.reload()
        }
    }

    const createButton = (user) => {
        if (user.active === 1) {
            return <Button size="sm" onClick={e => { blockOrUnblockUser(user)}}>ban</Button>
        }
        return <Button variant="warning" size="sm" onClick={e => { blockOrUnblockUser(user)}}>unban</Button>
    }

    return ( 
        <div>
        <Table striped hover style={css} size="sm" >
            <thead>
                <tr>
                    <th>Laatste Login</th>
                    <th>Voornaam</th>
                    <th>Achternaam</th>
                    <th>Email</th>
                    <th>Rol</th>
                    <th>Ban</th>
                    <th>Verwijder</th>


                </tr>
            </thead>

            <tbody>
                {props.users.map(user => {
                    const logged_in = new Date(user.last_logged_in)
                    return <tr key={user.id} style={{fontSize: '0.85rem'}}>
                        <td>{logged_in.toLocaleString('nl-NL')}</td>
                        <td>{user.name_first}</td>
                        <td>{user.name_last}</td>
                        <td>{user.email}</td>
                        <td>
                            <select defaultValue={user.role} onChange={async (e) => {
                                const new_role = e.target.value
                                const uri = `${API_URI}/user/updaterole`
                                await postToApi(uri, {role: new_role, user_hash: user.user_hash})
                            } } >
                                <option value='admin'>admin</option>
                                <option value='user'>user</option>
                            </select>
                        </td>
                        <td>{createButton(user)}</td>
                        <td><Button size="sm" onClick={e => { removeUser(user.user_hash) }} variant="danger">verwijder</Button></td>
                    </tr>
                })}
            </tbody>
        </Table>

        <AddUser />
        </div>
    )
}