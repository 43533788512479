import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import {API_URI} from '../uri'

import {generatePassword} from '../GeneratePassword'

const callApi = async (uri, data) => {
  return await fetch(uri, {
    method: 'post',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
}

export default function Login() {

  const [redirect, setRedirect] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [validated, setValidated] = useState(false)
  const [wrong_password, setWrongPassword] = useState(false)
  const [wrong_username, setWrongUsername] = useState(false)

  const [show_reset_message, setShowResetMessage] = useState(false)

  const [failed_attempts, setFailedAttempts] = useState(0)
  const max_failed_attempts = 3

  useEffect( () => {
    const query_params = new URLSearchParams(window.location.search)
    
    if (query_params.has('email'))    { setEmail(query_params.get('email') ) }
    if (query_params.has('password')) { setPassword( query_params.get('password') ) }
    if (query_params.has('redirect')) { setRedirect(true) }
  }, [] )


  function validateForm() {
    return password.length > 0;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget
    
    
    setValidated(true)
    setWrongUsername(false)
    setWrongPassword(false)

    const is_valid = form.checkValidity()

    if (is_valid) {
      const res = await fetch(`${API_URI}/gettoken`, {
        method: 'post',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({email, password})
      })
  
      if(res.ok) {
        const new_token = await res.json()
        sessionStorage.setItem('token', new_token)
  
        const uri_ = `${API_URI}/getuser`
        const response = await fetch(uri_, {
            method: 'post',
            headers: {
                  "Authorization": "Basic "+sessionStorage.getItem('token'),
                  'Content-Type': 'application/json',
            
            }	
        })
        const user = await response.json()
        sessionStorage.setItem('user', JSON.stringify(user))
        if (redirect) { 
          window.location.replace( "https://boodschappenbeheer.nl/#/account?tab=changepassword") 
        } 
        else  
          window.location.reload()
      }
      else {
        const code = res.status
        switch(code) {
          case 401: setWrongUsername(true); break
          case 403: { 
            setWrongPassword(true); 
            const failed = failed_attempts + 1
            setFailedAttempts(failed)
            break 
          }
          default: setWrongUsername(true) 
        }
    
        setValidated(false)
     
      }
    }
  }

  const passWordReset = async (e) => {
    e.preventDefault()
    if (email === "") {
      setWrongUsername(true)
      return
    }

    const password = generatePassword()

    const uri = `${API_URI}/resetpassword`
    const res = await callApi(uri, {email, password})
    if (res.ok) {
      setShowResetMessage(true)
      window.setTimeout ( () => { window.location.reload() }, 3000)
    }

  }

  if (show_reset_message) {
    return ( <><h3>Bedankt voor het insturen.</h3> <p>Je ontvangt binnenkort een email waarmee je je wachtwoord opnieuw kunt instellen.</p> </>)
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginBottom: '20px'}}>

      <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            isInvalid={wrong_username}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">Gebruiker niet bekend</Form.Control.Feedback>

        </Form.Group>

        <Form.Group size="lg" controlId="password">
          <Form.Label>Wachtwoord</Form.Label>
          <Form.Control
            type="password"
            value={password}
            isInvalid={wrong_password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">Wachtwoord onjuist</Form.Control.Feedback>
        </Form.Group>

        

        <Button block size="lg" type="submit" disabled={!validateForm()}>
          Login
        </Button>

        
      </Form>
      {failed_attempts >= max_failed_attempts ? <p>Wachtwoord vergeten? Klik <a href="/" onClick={ (e) => { passWordReset(e) }}>hier</a> om je wachtwoord te resetten.</p> : <></>}
      </>
  );
}
