import React, {useState} from 'react'
import {ListGroup, InputGroup, FormControl, Button, Spinner} from 'react-bootstrap'
import './ItemsList.css'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

import {postToApi} from '../callApi'
import { API_URI } from '../uri'

function ItemsList(props) {
    const [loading, setLoading] = useState(false)
    const [productName, setProductName] = useState(props.item.product)
    const [updated, setUpdated] = useState(false)
    const [crossed, setCrossed] = useState(props.item.crossed === 1)
    
    const {item} = props

    const extraInfo = () => {
        if (props.extrainfo) {
            return (<InputGroup.Append>
                <InputGroup.Text id="basic-addon2"> { props.extrainfo }</InputGroup.Text>
            </InputGroup.Append>)
        }
        else {
            return <></>
        }
    }

    
    const getCrossItemButton = () => {

        const handleCrossed = async () => {
            const uri = `${API_URI}/products/updatecrossed`
            const val = crossed ? 0 : 1
            const data = {
                id: item.id,
                crossed: val
            }
            await postToApi(uri, data)
        }

        if (props.crossable) {
            
            return (
                <Button variant={!crossed ? "outline-info" : "outline-danger" } onClick={e => {
                    setCrossed(!crossed);
                    handleCrossed()
                }}> {`\u2713`}  </Button>
            )
            
        }
        else {
            return (<></>)
        }
    }

    const getFormControl = () => {
        if (props.editable) {
            const borderStyle = {
                border: updated ? '2px solid lightgreen' :  '',
            }
    
            return <FormControl style={borderStyle} onChange={handleInputChange} value={productName} placeholder={`${productName}`}></FormControl>
        }
        else {
            const stale_after = 14
            const crossedText = crossed ? 'line-through' : ''
            const crossedStyle = {
                textDecoration: crossedText,
                color: props.staleness > stale_after ? '#bbb' : ''
            }
            const new_name = props.staleness > stale_after ? `${productName}?` : `${productName}`

            return <FormControl  value={new_name} disabled style={crossedStyle} ></FormControl>
        }
    }


   
    const updateProductAPI = async (userInput) => {
        const ding = {id: item.id, newProductName: userInput}

        const uri = `${props.endpoint}/updateitem`
        
        await postToApi(uri, ding)
    }

    const debouncedUpdateProductAPI = AwesomeDebouncePromise(updateProductAPI, 500)

    const handleInputChange = async (e) => {
        const userInput = e.target.value
        setProductName(userInput)
        await debouncedUpdateProductAPI(userInput)
        
        setUpdated(true)
        
    }

    if(loading) {
        return (
            <ListGroup.Item><Spinner animation="border" /></ListGroup.Item>
        )     
    }
    else {
        return(
            <ListGroup.Item style={{padding: '6px'}}>
                <InputGroup>
                    <InputGroup.Prepend>
                        {getCrossItemButton()}
                    </InputGroup.Prepend>
                    {getFormControl()}
                    
                    {extraInfo()}

                  
                    <InputGroup.Append>
                    <Button id={item.id} variant="outline-danger" onClick={async (e) => {
                        
                        const item = {id: e.target.id }
                        const uri =  `${props.endpoint}/removeitem` 
                        setLoading(true)

                        
                        if (props.refreshPage) {
                            await postToApi(uri, item, async (response) => {
                                if (response.status === 202) {
                                    setLoading(false)
                                    const text = await response.json()
                                   
                                    alert(text.message)
                                }
                                else 
                                    window.location.reload()
                            })
                        }
                        else {
                            setLoading(false)
                            props.removedItem(item.id)
                            await postToApi(uri, item)
                        }
                    }}>{`\u2573`}</Button> 
                    </InputGroup.Append>
                
                </InputGroup>
                
            </ListGroup.Item>

                
        )

    }
}

export default ItemsList