import React, {useEffect, useState} from 'react'
import {API_URI} from '../../uri'
import {Tabs, Tab} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'

import ChangePassword from '../ChangePassword'
import UserOverview from '../User.Overview'


export default function AccountPage() {

    const location = useLocation()

    const [user, setUser] = useState({})
    const [key, setKey] = useState('personal')

    const [fetched, setFetched] = useState(false)

    useEffect(() => {
        async function getUserData() {
            const the_token = sessionStorage.getItem("token")
            if (the_token) {
        
                const res = await fetch(`${API_URI}/getuser`, {
                    method: 'post',
                    headers :{
                        'Authorization': "Basic "+the_token
                    }
                })
        
                const json = await res.json()
                
                setUser(json)
            }
        }

        if (!fetched) {
            getUserData()
            setFetched(true)
        }


        const query_params = new URLSearchParams(location.search)
        const available_keys = {
            'personal': true,
            'changepassword': true
        }

         // Check url to see if we want to load change password tab
         if (query_params.has('tab')) {
            const tab = query_params.get('tab') 
            if (available_keys[tab]) {
                setKey(tab)
            }
        }
    }, [setFetched, fetched, location])


   

    if(user) {
        
        return(
            <Tabs 
            id="account-tabs"
            transition={false}
            activeKey={key}
            onSelect={(e) => setKey(e)}
            >
                <Tab eventKey="personal" title="Personalia">
                    <br />
                    <UserOverview user={user} />
                </Tab>

                <Tab eventKey="changepassword" title="Verander wachtwoord">
                    <br />
                    <ChangePassword user={user} />
                </Tab>
            </Tabs>
        )
    }
    else {

        return (
            <p>...</p>
        )
    }
}