import React, { useEffect, useState } from 'react'
import {Navbar, Nav, NavbarBrand} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import "./Navigation.css";

function Navigation(props) {    
    const {links} = props;
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    function handleWidthChange() {
        setScreenWidth(window.innerWidth);
    }

    useEffect(() => {
        return () => window.removeEventListener('resize', handleWidthChange);
    });


    window.addEventListener('resize', handleWidthChange);
    
    const handleLogOut = (e) => {
        e.preventDefault()
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')
        window.location.replace('/');
    }

    const maxW = 600;

    const css = {
        justifyContent: "space-between",
    };

    return (
        <Navbar style={css} bg="dark" variant="dark" >
            <Nav  >
                <NavbarBrand id="Brand" as={Link} to="/">{screenWidth < maxW ? 'B' : 'Boodschappen'} </NavbarBrand>
                {links.map(link => {
                    return (
                        <Nav.Item key={link.path}>
                            <Nav.Link  as={Link} to={link.path}>{link.title}</Nav.Link>
                        </Nav.Item>
                    )
                })}

                
            </Nav>
            <Nav.Item key='logout'> 
                <Nav.Link style={{color: "darkgray"}} onClick={handleLogOut}>Log uit</Nav.Link>
            </Nav.Item>

           
        </Navbar>
    )
   

}

export default Navigation
