import React from 'react'
//import AddProduct from './AddProduct'
import AddCategory from './AddCategory'
import { ModalTitle } from 'react-bootstrap'
import ItemsList from './ItemsList'

import {API_URI} from '../uri'

function Categories(props) {

    return (
        props.categories.map(item => {
                item.product = item.name
                return <ItemsList refreshPage extrainfo={item.order_index} key={item.product} item={item} endpoint={`${API_URI}/categories`}  />
        })
    )
}


export default function AdminCategories (props) {
    return (
        <>
        <ModalTitle className="mb-3"><em>Admin</em> - Voeg categorie toe</ModalTitle>
        <AddCategory />
        <Categories categories={props.categories} />
        </>
    )
}
