import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {API_URI} from '../uri'
import {postToApi} from '../callApi'

export default function ChangePassword(props) {
  const [password, setPassword] = useState("");
  

  function validateForm() {
    return password.length > 0;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const {email} = props.user

    await postToApi(`${API_URI}/savepassword`, {email, password}) 
   
    window.location.reload()
  }


    return (
      <div className="Login">
        <Form onSubmit={handleSubmit}>
  
      
        <h3>Verander wachtwoord</h3>
  
          <Form.Group size="lg" controlId="password">
            <Form.Label>Nieuw wachtwoord</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button block size="lg" type="submit" disabled={!validateForm()}>
            Verstuur
          </Button>
        </Form>
      </div>
    );
  
}
