import React, {useState, useEffect} from 'react';
import {Spinner} from 'react-bootstrap'
import {API_URI} from '../uri'

import {getFromApi, postToApi} from '../callApi'

import CategoryGroup from './CategoryGroup'
import ItemsList from './ItemsList'
import AddItem from './AddItem';


function Shoppinglist(props) {


    const [categories, setCategories] = useState([])
    const [shoppinglist, setShoppinglist] = useState([])
    const [loading, setLoading] = useState(false)

 
    

    const filterCategories = async (list) => {
        let filtered_categories = []
        list.forEach(item => {
            if (!filtered_categories.find((categ) => categ.id === item.category_id )) {
                filtered_categories = [...filtered_categories, {id: item.category_id, index: -1}]
            }
        })

        const getFullCategory = async (category) => {
            const {id} = category
        
            const uri = `${API_URI}/category/get`
            const res = await postToApi(uri, {id})
            return res[0]
        }
       
        const named_categories = await Promise.all ( filtered_categories.map(getFullCategory)  )
       
        named_categories.sort((a, b) => a.order_index - b.order_index)
        
        setCategories(named_categories)  
    }

    const handleAddedProduct = (new_product) => {
        const list = [...shoppinglist]
        list.push(new_product)
        setShoppinglist(list)
        filterCategories(list)
    }

    const handleRemovedItem = (the_id) => {
        const new_list = shoppinglist.filter( item => {return item.id !== +the_id } )
        setShoppinglist(new_list)
        filterCategories(new_list)
    }

    useEffect(() => {
        
        const getShoppingList = async () => {
            setLoading(true)
            
            const uri =  `${API_URI}/shoppinglist`
            const list = await getFromApi(uri)
            
            setShoppinglist(list)
            
            await filterCategories(list)
            setLoading(false)
        }

        getShoppingList()
    }, [setCategories, setLoading])

   

    const createCategorizedShoppingList = () => {

        if (loading) {
            return (
                <div style={{textAlign: 'center', pading: '20px'}}>
                    <Spinner animation="border"/>
                </div>
            )
        }
        else if (categories[0])  {
            
            // just, re-order list everytime we update.. 
            categories.sort((a,b) => {
                if (a.index < b.index) return -1
                if (a.index > b.index) return 1
                return 0
            })

            const itemInfo = (item) => {
                let info = "";
            
                info += item.qty ? item.qty : "";   
                info += item.unit ? " "+item.unit : "";
                return info;
            }

            const AllGroupedItems = categories.map(category => {
                const relevantItems = shoppinglist.filter(item => item.category_id === category.id)
                const sortedItems = relevantItems.sort((a,b) => {
                    if(a.product < b.product) { return -1; }
                    if(a.product > b.product) { return 1; }
                    return 0;
                })
                return <CategoryGroup key={category.id} title={category.name} opened={true} >
                    {sortedItems.map(item => {
                        // Calculate staleness
                        const time_added = new Date(item.datetime_added)
                        const now = new Date()
                        const time_diff = Math.round(now - time_added)
                        const days = Math.round(time_diff / 1000 / 60 / 60 / 24)
                        console.log(days)
                        return <ItemsList staleness={days} removedItem={handleRemovedItem} crossable  key={item.product} item={item} endpoint={`${API_URI}/shoppinglist`} extrainfo={itemInfo(item)} />
                    }
                    )}
                    </CategoryGroup>
            })
            return (
                <>
                {AllGroupedItems}
                </>
            )
        }
        else {
            return <h5 style={{textAlign: 'center', margin: '2rem'}}>Geen boodschappen in lijstje.</h5>
        }
    }

    

    
    return (
        <>
        <AddItem addedProduct={handleAddedProduct}/>
        {createCategorizedShoppingList()}
        </>
    )

}

export default Shoppinglist