import React, {useState, useEffect} from 'react'
import AdminCategories from '../Admin.Categories'
import AdminProducts from '../Admin.Products'
import UsersOverview from '../Admin.UsersOverview'
import GroupsOverview from '../Admin.Groups'
import {Tabs, Tab} from 'react-bootstrap'

import {API_URI} from '../../uri'
import {getFromApi, postToApi} from '../../callApi'


export default function AdminPage () {
    const [key, setKey] = useState('products')
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [groups, setGroups] = useState([])
    const [users, setUsers] = useState([])
    const [fetched, setFetched] = useState(false)

    useEffect(() => {
        const getCategories = async () => {
            const uri =  `${API_URI}/categories`
            const new_categories = await getFromApi(uri)

            new_categories.sort( (a, b) => a.name.localeCompare(b.name) )
            setCategories(new_categories)
            
        }

        const getProducts = async () => {
            const uri =  `${API_URI}/products`
            const products = await getFromApi(uri)
          
            //Sort by product name
            products.sort((a, b) => {
                if(a.product < b.product) { return -1; }
                if(a.product > b.product) { return 1; }
                return 0;
            })
    
            setProducts(products)
        }

        const getGroups = async () => {
            const uri = `${API_URI}/groups`
            const new_groups = await getFromApi(uri)
            setGroups(new_groups)
        }

        const getUsers = async () => {
            const uri = `${API_URI}/getusers`
            const new_users = await postToApi(uri)
            setUsers(new_users)
        }

        const concurrent = async () => await Promise.all( [getCategories(), getProducts(), getGroups(), getUsers()] )

        concurrent()
        setFetched(true)
       
    }, [setFetched, setProducts, setCategories, setGroups, setUsers])
    

    if (fetched) {
        return (
            <Tabs
                id="admin-tabs"
                transition={false}
                activeKey={key}
                onSelect={(e) => setKey(e)}
            >
                <Tab eventKey="products" title="Producten">
                    <br />
                    <AdminProducts products={products} categories={categories} />
                </Tab>
    
                <Tab eventKey="categories" title="Categoriëen">
                    <br />
                    <AdminCategories categories={categories} />
                </Tab>
    
                <Tab eventKey="usersoverview" title="Gebruikers">
                    <br />
                    <UsersOverview  users={users} />
                </Tab>
               
               

                <Tab eventKey='groups' title='Groepen'>
                    <br />
                    <GroupsOverview groups={groups} users={users}/>
                </Tab>
            </Tabs>
        )
    }

    return (<></>)

}
