import React from 'react';
import {
    InputGroup, 
    FormControl, 
    Button,
   
} from 'react-bootstrap'
import {API_URI} from '../uri'

import {postToApi} from '../callApi'

class AddProduct extends React.Component {

    state = {
        category: {name: "Aardappelen", id: 27, order_index: 2}, //TODO: dont hardcode
        product: {}
    }
        

    handleCategoryChange = async (e) => {
        const uri = `${API_URI}/category/get`
        const new_category = await postToApi(uri, {id: e.target.value})
        this.setState(
            { category: new_category[0] }
        )
    }

    handleProductChange = (e) => {
        this.setState(
            { product: {product: e.target.value } }
        )
    }

    

    handleClick = async (e) => {
        const uri = `${API_URI}/products/additem`;
        let new_product = this.state.product
        new_product.category_id = this.state.category.id
        new_product.category = this.state.category.name
        await postToApi(uri, new_product)
        window.location.reload(false);
        
    }


    render() {
      
        return (
           <InputGroup className="mb-3">
                <FormControl placeholder="Productnaam" onChange={this.handleProductChange}></FormControl> 
                <FormControl onChange={this.handleCategoryChange}
                    as="select"
                    variant="outline-secondary"
                    id="input-group-dropdown-1" 
                >
                    {this.props.options.map(option => {
                        return <option key={option.id} value={option.id}>{option.name}</option>
                    })}
                </FormControl>
                
                <InputGroup.Append>
                    <Button onClick={this.handleClick} variant="outline-secondary">Voeg toe</Button>
                </InputGroup.Append>
           </InputGroup>
        )
    }
}

export default AddProduct