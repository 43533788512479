import React from 'react';
import {
    InputGroup, 
    FormControl, 
    Button,
   
} from 'react-bootstrap'
import { API_URI } from '../uri';
import {postToApi} from '../callApi'

class AddCategory extends React.Component {

    state = {
        category: {}
    }
    
    handleCategoryChange = (e) => {
        this.setState({ 
            category: { name: e.target.value } 
        })
    }


    handleClick = async (e) => {
        const uri = `${API_URI}/categories/additem`;

        await postToApi(uri, this.state.category)
    
        
        window.location.reload();
        
    }

    render() {
        
        return (
           <InputGroup className="mb-3">
                <FormControl placeholder="Categorie" onChange={this.handleCategoryChange}></FormControl> 
                                
                <InputGroup.Append>
                    <Button onClick={this.handleClick} variant="outline-secondary">Voeg toe</Button>
                </InputGroup.Append>
           </InputGroup>
        )
    }
}

export default AddCategory