import React, {useEffect, useState} from 'react'
import {ListGroup, Table, InputGroup, Form, Button} from 'react-bootstrap'
import { postToApi } from '../callApi'
import { API_URI } from '../uri'


function AddUser (props) {

    const [available_users, setAvailableUsers] = useState([])
    const [active_user_hash, setUserHash] = useState("")

    useEffect(() => {
        const filtered = props.users.filter(user => {
            const index = props.members ? props.members.findIndex(member => member.id === user.id) : -1
            return !(index >= 0)
        })
        if (filtered[0]) setUserHash(filtered[0].user_hash)
        setAvailableUsers(filtered)
    }, [setAvailableUsers, setUserHash, props])

    const handleChangeUser = (e) => {
        const user_hash = e.target.value
        setUserHash(user_hash)
    }

    const handleSubmit = async () => {
        const uri = `${API_URI}/groups/adduser`
        if (active_user_hash !== '') {
            const data = {
                user_hash: active_user_hash,
                group_hash: props.group.group_hash
            }
            
            await postToApi(uri,data)
            window.location.reload()
        }
    }

    return (
        <InputGroup className="mb-3">
            <Form.Control as='select' placeholder="Gebruiker" onChange={handleChangeUser}>
                {available_users.map(user => <option key={user.id} value={user.user_hash} >{user.name_first} {user.name_last}</option>)}
            </Form.Control> 
                            
            <InputGroup.Append>
                <Button onClick={handleSubmit} variant="outline-secondary">Voeg toe</Button>
            </InputGroup.Append>
        </InputGroup>
    )
}

function Bundle (props) {
    const [open, setOpen] = useState(true)
    const {name, members} = props.group

    const removeUser = async (user_hash, group_hash) => {
        const uri = `${API_URI}/groups/removeuser`
       
        const data = {
            user_hash,
            group_hash
        }
        
        await postToApi(uri,data)
        window.location.reload()
    
    }

    if (members) {

        return(
            <ListGroup style={{marginBottom: '1rem'}}>
                <ListGroup.Item onClick={e => { setOpen(!open) }} className={'over'} variant="primary">{name}</ListGroup.Item>
                    <Table hover size="sm">
                        <tbody>
                        {members.map( member => { 
                            return (  <tr key={member.id}>
                                <td>{member.name_first} {member.name_last}</td>
                                <td>{member.email}</td>
                                <td>{member.role}</td>
                                <td><Button size="sm" variant='danger' onClick={e => { removeUser(member.user_hash, props.group.group_hash) } } >verwijder</Button></td>
                            </tr>  )
                        })}
                        </tbody>
                    </Table>   
                    <AddUser group={props.group} users={props.users} members={members} />
            </ListGroup>
        )
    } 

    return(
        <ListGroup style={{marginBottom: '1rem'}}>
            <ListGroup.Item onClick={e => { setOpen(!open) }} className={'over'} variant="primary">{name}</ListGroup.Item>
                <AddUser group={props.group} users={props.users} members={members} />
        </ListGroup>
    )
    
}

function CreateGroup () {
    const [group_name, setGroupName] = useState("")

    const createGroup = async () => {
        if (group_name !== "") {
            const uri = `${API_URI}/creategroup`
            await postToApi(uri, {name: group_name})
            window.location.reload()
        }
    }

    return (
        <InputGroup className="mb-3">
             <Form.Control placeholder="Groepsnaam" onChange={e => {setGroupName(e.target.value)}}></Form.Control> 
                             
             <InputGroup.Append>
                 <Button onClick={createGroup} variant="outline-secondary">Voeg toe</Button>
             </InputGroup.Append>
        </InputGroup>
     )
}

export default function GroupsOverview(props) {
    const available_users = props.users.filter(user => user.group_hash === 'no_group')
    return (
        <>
            <CreateGroup />
            {props.groups.map(group => <Bundle key={group.group_hash} group={group} users={available_users}></Bundle>)}
        </>
    )
}