import React from 'react'

export default function UserOverview(props) {
    const {name_first, name_last, email} = props.user

    return (
        <div>
            <h3>{name_first} {name_last}</h3>
            <h5>{email}</h5>
        </div>
    )
}