import React, { useEffect, useState } from 'react'
import {ListGroup, Collapse, InputGroup, FormControl, Button} from 'react-bootstrap'
import { postToApi } from '../callApi'
import { API_URI } from '../uri'
import './CategoryGroup.css'

function AddProduct (props) {

    const [fetched, setFetched] = useState(false)
    const [product, setProduct] = useState({})
    const [category_id, setCategoryId] = useState(-1)


    useEffect(() => {
        const getCategory = async () => {
            const uri = `${API_URI}/category/find`
            const details = await postToApi(uri, {name: props.category})
            setCategoryId(details.id)
        }

        if (!fetched) {
            setFetched(true)
            getCategory()
        }
    }, [setCategoryId, props, fetched])

    const handleProductChange = (e) => {
        e.preventDefault()

        const val = e.target.value
        setProduct(val)
    }

    const handleClick = async (e) => {
        e.preventDefault()

        if (category_id > -1) {
            const product_data = {
                product,
                category: props.category,
                category_id
            }
            const uri = `${API_URI}/products/additem`
            const json = await postToApi(uri, product_data)
            if (json.message) {
                
                window.location.reload()
            }
            else {
                alert("Er is iets misgegaan")
            }
            
        }
        
    }

    return (
        <InputGroup className="mb-3">
                <FormControl placeholder="Productnaam" onChange={handleProductChange}></FormControl> 
                
                <InputGroup.Append>
                    <Button onClick={handleClick} variant="outline-secondary">Voeg toe</Button>
                </InputGroup.Append>
           </InputGroup>
    )
}

function CategoryGroup(props) {
    const [open, setOpen] = useState(props.opened)
    return(
        <ListGroup style={{marginBottom: '1rem'}}>
            <ListGroup.Item className={'over'} onClick={(e) => setOpen(!open)}  variant="primary">{`${props.title} (${props.children.length})`}</ListGroup.Item>
            <Collapse in={open}>
                <div>
                    {props.children}
                    {props.showaddbar ? <AddProduct category={props.title} /> : <></>}    
                </div>
            </Collapse>
            
        </ListGroup>
    )
}

export default CategoryGroup