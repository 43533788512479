import React, {useState, useEffect} from 'react'
import { ModalTitle, Spinner } from 'react-bootstrap'
import CategoryGroup from './CategoryGroup'
import AddProduct from './AddProduct'
import ItemsList from './ItemsList'
import {API_URI} from '../uri'


function Products(props) {

    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)
  

    const filterCategories = (list) => {
        let filtered_categories = []
        list.forEach(item => {
            // Category not present, add it
            if (!filtered_categories.find((categ) => categ === item.category_name ))
                filtered_categories =  [...filtered_categories, item.category_name]
            
        })

        filtered_categories.sort((a, b) => {
            if(a < b) { return -1; }
            if(a > b) { return 1; }
            return 0;
        })

       setCategories(filtered_categories)
    }

    useEffect( () => {
        filterCategories(props.products)
        setLoading(false)
    }, [props])


    if (loading || categories[0] == null) {
        return (
            <div style={{textAlign: 'center', pading: '20px'}}>
                <Spinner animation="border"/>
            </div>
        )
    }
    else {
        return (
            <>
            {categories.map(category => {
                
                const items = props.products
                .filter(item => item.category_name === category)
                .sort((a,b) => {
                    if(a.product < b.product) { return -1; }
                    if(a.product > b.product) { return 1; }
                    return 0;
                })
                return (
                    <CategoryGroup 
                        key={category} 
                        title={category} 
                        items={items} 
                        endpoint={`${API_URI}/products/removeitem`} 
                        showaddbar
                    >
                        {items.map(item => 
                            <ItemsList refreshPage editable key={item.product} item={item} endpoint={`${API_URI}/products`}  />
                        )}             
                    </CategoryGroup>
                )
            })}
            </>
        
        )
    }
}


export default function AdminProducts(props) {
    return (
        <>
        <ModalTitle className="mb-3"><em>Admin</em> - Voeg product toe</ModalTitle>
        <AddProduct defaultOption={props.categories[0]} options={props.categories}/>
        <Products products={props.products} />
        </>
    )
}

