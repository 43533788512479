import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {API_URI} from '../uri'
import { postToApi } from "../callApi";

import {generatePassword} from '../GeneratePassword'

export default function AddUser() {
  const [email, setEmail] = useState("");
  const [name_first, setFirstName] = useState("");
  const [name_last, setLastName] = useState("");

  const [validated, setValidated] = useState(false)


  function validateForm() {
    return  email.length > 0 && name_first.length > 0 && name_last.length > 0;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setValidated(true)
    const form = e.currentTarget

    if (form.checkValidity()) {
      const strong_password = generatePassword()
      const uri = `${API_URI}/user/create`
      await postToApi(uri, {name_first, name_last, email, password: strong_password})
      
      window.location.reload()
    }

    
  }

  if (true) {
      return (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
    
            <Form.Group size="lg" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
    
            
            <Form.Group size="lg" controlId="name_first">
              <Form.Label>Voornaam</Form.Label>
              <Form.Control
                type="name"
                value={name_first}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
    
            <Form.Group size="lg" controlId="name_last">
              <Form.Label>Achternaam</Form.Label>
              <Form.Control
                type="name"
                value={name_last}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
    
            <Button block size="lg" type="submit" disabled={!validateForm()}>
              Voeg toe
            </Button>
          </Form>
      );
  }
  else {
      return (
        <div style={{textAlign: 'center'}}>
          <h3>Gebruiker succesvol toegevoegd.</h3>

        </div>
      )
      
  }

}
